* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Sora", sans-serif;
}

:root {
  --primary-color: rgba(24, 128, 56, 1);
}

ul {
  list-style: none;
  padding-left: 0;
}

a {
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0;
}

/* p,h1,h2,h3,h4,h5,h6{
  margin: 0 !important;
  padding: 0 !important;
} */

.logo img {
  width: 40px;
}

.page-alignment {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#root {
  background: #edf1f7;
  min-height: 100vh;
}
.form-wrapper {
  width: 30%;
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 25px 0 rgba(18, 41, 60, 0.1);
}

.login-para {
  font-size: 20px;
  margin: 30px 0 20px 0 !important;
}
input[type="password"],
input[type="tel"],
input[type="text"] {
  height: 50px;
}
.intl-tel-input {
  display: block !important;
  border-radius: 0 !important;
}
figure.logo {
  margin-bottom: 0;
}
input:focus {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #ddd !important;
}

.fix-btn-bg {
  margin-top: 15px;
}
.btn-outline:hover {
  color: #dfdfdf;
  transition: 0.4s all ease;
  background: #229556;
}
.btn-outline:hover span {
  transition: 0.4s all ease;
  color: #fff;
}
.btn-outline {
  color: #181818;
  transition: 0.5s all ease;
  font-weight: 800;
  background: rgb(255 255 255);
  border: 1px solid #229556;
  padding: 10px 30px;
  font-size: 18px;
  border-radius: 5px !important;
  letter-spacing: 0.2px;
  text-decoration: none;
  display: block;
  text-align: center;
}
.btn-main {
  color: #ffffff;
  font-weight: 600;
  background: rgba(24, 128, 56, 1);
  padding: 10px 30px;
  font-size: 16px;
  border-radius: 5px !important;
  letter-spacing: 0.2px;
  text-decoration: none;
  display: block;
  text-align: center;
  text-transform: uppercase;
}

.btn-main:hover {
  color: #fff;
}

.back-btn-div i,
.back-btn-div span {
  color: var(--primary-color);
}

.verify-content h1 {
  font-size: 30px;
  font-weight: 500;
}

.verify-content p {
  margin-top: 28px !important;
  margin-bottom: 10px;
}
input[type="email"] {
  height: 50px;
}
.inputstyle {
  margin-top: 10px;
  height: 50px;
}

.didnotget-code {
  margin-top: 10px;
}

.didnotget-code p,
.didnotget-code a {
  font-size: 16px;
}

.didnotget-code a {
  color: var(--primary-color);
  font-weight: 500;
  display: block;
}

.section-gutter {
  padding: 80px 0;
}

.top-align {
  padding-top: 120px;
}

.termsservice-desc h1 {
  color: var(--primary-color);
  font-size: 32px;
  margin-bottom: 30px;
}

.termsservice-desc h4 {
  font-size: 18px;
}

.termsservice-desc p {
  font-size: 16px;
  margin: 15px 0;
  text-align: justify;
}

.term-service-btn {
  color: #ffffff;
  font-weight: 800;
  background: rgba(24, 128, 56, 1);
  padding: 10px 30px;
  font-size: 18px;
  border-radius: 45px;
  letter-spacing: 0.2px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  margin-top: 30px;
}

.term-service-btn:hover {
  color: #fff;
}

.input-icon .inputstyle {
  margin-top: 0 !important;
  background: none;
  border: none;
}

.input-icon {
  border: 1px solid #bfbfbf;
  padding: 0 10px;
  border-radius: 5px;
  background-color: #fff;
}

.verify-content i {
  color: var(--primary-color);
}

.verify-content p i {
  margin-right: 10px;
}

/* DashBoard */
header {
  background: rgb(255 255 255);
  z-index: 9999;
  padding: 10px 20px;
  height: 62px;
  position: fixed;
  width: 100%;
  -webkit-box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
}
header .fas {
  font-size: 28px;
  width: 30px;
  cursor: pointer;
  margin-top: 5px;
}
header .left-side a {
  color: #4e4e4e;
  font-weight: 600;
}
header .right-side {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
header .right-side a i {
  margin-right: 20px;
  background: #229556;
  height: 40px;
  width: 40px;
  padding: 10px 12px;
  border-radius: 50%;
  color: #fff;
}
header .left-side {
  display: flex;
  align-items: center;
  gap: 50px;
}

.heading i {
  font-size: 25px;
  width: 25px;
  cursor: pointer;
}
.heading .logo {
  text-align: left;
}

.heading .logo img {
  margin-left: 20px;
  width: 28%;
}

.dashboard-firstdesc {
  padding: 42px 20px;
  border-radius: 4px;
  border: 1px solid #d2d2d221;
  text-align: center;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
}

.dashboard-firstpart {
  padding-top: 100px;
}

.dashboard-firstdesc span {
  color: #a4a4a4;
}

.dashboard-firstdesc h2 {
  font-size: 50px;
  color: var(--primary-color);
  font-weight: 800;
  text-align: center;
  margin: 20px 0;
}

.dashboard-firstdesc p {
  color: #555555;
  font-size: 17px;
  font-weight: 700;
}

.missed-doc-wrapper,
.currentride-bg,
.faqlist-bg {
  background: rgb(255 255 255);
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  padding: 40px;
}

.dashboard-icon-bg {
  background: rgb(255 255 255);
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  padding: 30px;
  width: 100%;
}

.slider-card-photo-missed {
  display: flex !important;
}

.slider-card-photo-missed i {
  margin: 0px 20px 0 0;
  font-size: 20px;
  color: var(--primary-color);
}

.from-to-address p {
  position: relative;
}

.from-to-address p:first-child:before {
  content: "";
  position: absolute;
  right: 102%;
  top: 5px;
  width: 15px;
  height: 15px;
  border: 4px solid var(--primary-color);
  background: #fff;
  border-radius: 50%;
}

.from-to-address p:last-child:before {
  content: "";
  position: absolute;
  right: 102%;
  top: 5px;
  width: 15px;
  height: 15px;
  border: 4px solid #ff0000;
  background: #fff;
  border-radius: 50%;
}

.driver-image img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.driver-call-icon i {
  margin-right: 20px;
  color: var(--primary-color);
}

.drivername-rating h5 {
  margin-bottom: 0;
}

.home-icon a {
  text-decoration: none;
  margin: 20px 0;
}

.home-icon a i {
  padding: 0 9px;
  color: rgba(24, 128, 56, 1);
  font-size: 18px;
}

.home-icon a span {
  align-items: center;
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.sidebar {
  overflow: scroll;
  overflow-x: visible;
  overflow-y: auto;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 30px 30px;
  width: 300px;
  position: fixed;
  top: 75px;
  background: #f1f1f1;
  z-index: 99;
  transform: translateX(-300px);
  transition: 0.7s ease-in-out;
}

.sidebar.open-menu {
  transform: translateX(0);
  transition: 0.7s ease-in-out;
  box-shadow: 0 0 50px 0 rgba(2, 2, 2, 0.11);
  background: #fff;
}

.body-wrapper.open-menu {
  width: calc(100% - 300px);
  transform: translateX(300px);
  transition: 0.7s ease-in-out;
}

.body-wrapper {
  width: 100%;
  overflow: hidden;
  transition: 0.7s ease-in-out;
}

.sidebar-img img {
  width: 70px;
}

.sidebar-img h4 {
  margin-top: 20px;
  margin-bottom: 0;
}

.menu-list {
  padding: 0;
}

.menu-list li:first-child {
  border-top: 0;
}
.menu-list li a {
  text-transform: uppercase;
}
.menu-list li {
  padding: 15px 0;
  border-bottom: 1px solid #f3f3f3;
}

.menu-list li i {
  color: var(--primary-color);
  margin-right: 22px;
}

.menu-list li span {
  color: #000;
  font-weight: 400;
}

.profile-align {
  width: 12%;
  margin: 0 auto;
}

/* Tabs */
.react-tabs__tab {
  padding: 10px 55px !important;
}

.react-tabs__tab--selected {
  background: #f1f1f1 !important;
  border-color: #d4cece !important;
  color: #716969 !important;
  border-radius: 5px 5px 0 0;
}

.booking-comp .address p {
  position: relative;
}

.address p:first-child:before {
  content: "";
  position: absolute;
  right: 102%;
  top: 5px;
  width: 15px;
  height: 15px;
  border: 4px solid var(--primary-color);
  background: #fff;
  border-radius: 50%;
}

.address p:nth-child(2):before {
  content: "";
  position: absolute;
  right: 102%;
  top: 5px;
  width: 15px;
  height: 15px;
  border: 4px solid #ff0000;
  background: #fff;
  border-radius: 50%;
}

.booking-comp,
.promotional-link {
  padding: 42px 60px;
  border-radius: 4px;
  border: 1px solid #d2d2d221;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
}

.calender-totalspent {
  padding-top: 25px;
  border-top: 1px solid #ccc;
}

.calender-totalspent h5 {
  font-size: 15px !important;
  font-weight: 500 !important;
  margin-left: -23px !important;
}

.calender-totalspent h5 i {
  color: var(--primary-color);
  margin-right: 10px;
}

.notification-tab .react-tabs__tab {
  padding: 10px 80px !important;
}

.promotional-link i {
  color: #fff;
  background: var(--primary-color);
  padding: 10px;
  border-radius: 50%;
  margin-right: 15px;
  margin-top: 15px;
}

.promotional-link h5 {
  font-size: 18px;
  color: #000;
  margin-bottom: 0;
}

.promotional-link p {
  font-size: 14px;
  color: #000;
  margin-top: 5px;
}

.statement-invoice-bg {
  background-color: var(--primary-color);
  color: #fff;
  padding: 24px;
  border-radius: 5px;
}

.dr-cr-wrapper {
  background: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin-top: 10px;
  color: #000;
}

.react-tabs__tab-list {
  border-bottom: none !important;
}

.table-download {
  color: var(--primary-color);
}

.table > :not(:first-child) {
  border-top: none !important;
}

.faq-list i {
  color: var(--primary-color) !important;
}

.faq-list li {
  cursor: pointer;
  padding: 15px 0;
  border-bottom: 1px solid #d6d6d6 !important;
}

.faq-list-icon-heading i {
  font-size: 20px;
  margin-right: 15px;
}

.faq-list-icon-heading span {
  color: #000;
}

.edit-form-bg,
.payment-card-bg {
  background: #f4f4fa;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  padding: 20px 40px;
}

.edit-form-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.edit-form-title i {
  color: var(--primary-color);
}

.modal-dialog {
  margin-top: 200px;
}

.modal-header,
.modal-footer {
  border: none;
}

.editprofile-icon i {
  color: var(--primary-color);
  margin-left: 40px;
}

.profile-align h4 {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 0;
}

.viewprofile p {
  display: contents;
}

.address p:nth-child(2) {
  padding-bottom: 15px;
}

.payment-card-bg h2 {
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}

/* .page-alignment .form-wrapper{
  width: 30% !important;
} */
.payment-modal .modal {
  position: absolute !important;
  top: 100px !important;
}

.modal-content {
  background-color: #edf1f7;
}

.modal.show .modal-dialog {
  margin: 0 auto !important;
}

.modal.show {
  display: block;
  height: 100%;
  width: 100%;
  top: 120px;
  overflow: hidden;
}

.payment-modal .form-wrapper {
  width: 100%;
}

.payment-modal .page-alignment {
  height: 70vh;
}

.slider-card-photo-missed div a {
  margin-top: 15px;
  display: block;
}
.active-card {
  background: var(--primary-color);
  transform: scale(1.03);
  color: #fff;
  border-radius: 5px;
}

.searchinvoice-stmnt-card {
  background: #f4f4fa;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  padding: 40px;
}
.searchinvoice-stmnt-card h1 {
  font-size: 23px;
}
.searchinvoice-stmnt-card p {
  margin-bottom: 10px;
  font-weight: 400;
}
.generate-statement .btn-main:first-child {
  border-radius: 5px;
}
.faq-qu-ans {
  background: #f4f4fa;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  padding: 20px;
  margin-top: 10px;
}
.accordion-item {
  border: none;
}
.accordion-body {
  padding: 0;
}
.accordion-button {
  display: inline;
}
.accordion-button:focus {
  background: #fff !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  z-index: 0 !important;
}
.icon-colouring i {
  color: var(--primary-color);
}
.icon-colouring {
  border: none;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}
.accordion-button:not(.collapsed) {
  background: none;
}
.accordion-button {
  transition: none;
}
.support-acc-form-bg {
  background: #f4f4fa;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  padding: 20px;
}
.btn-small {
  color: #ffffff;
  font-weight: 500;
  background: rgba(24, 128, 56, 1);
  padding: 5px 15px;
  font-size: 15px;
  border-radius: 30px;
  letter-spacing: 0.2px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
}
.btn-small:hover {
  color: #fff;
}
.userlogin-or {
  text-align: center;
  display: block;
  margin: 5px 0;
  color: #000;
  font-weight: 400;
}
.faqlogout-bg {
  background: rgb(255 255 255);
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  padding: 20px 40px;
}
.faqlogout-bg i {
  color: var(--primary-color);
}
.btn-inline {
  display: inline;
  font-weight: 700;
}
.custom-card {
  background: #fff;
  padding: 30px 20px;
  box-shadow: 0 0 25px 0 rgb(18, 41, 60, 0.1);
  margin-bottom: 30px;
  border-radius: 10px;
}
.missed-info-img {
  width: 130px;
}
.missed-info {
  text-align: center;
}
.missed-info h4 {
  font-size: 18px;
  padding: 30px 0 5px 0;
  font-weight: 700;
  text-transform: uppercase;
  color: #5c5c5c;
}
.dropdown {
  display: inline-block;
}
header .btn-inline.btn-outline {
  font-size: 15px;
  padding: 8px 30px;
  text-transform: uppercase;
  margin-top: 5px;
}
header .fa-bell {
  font-size: 18px;
}
header .fa-user {
  font-size: 22px;
  position: relative;
  top: 5px;
  left: -10px;
  color: rgb(160, 160, 160);
}

header .dropdown {
  text-transform: uppercase;
}
header .dropdown button {
  text-transform: uppercase;
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.dropdown-menu {
  min-width: 195px;
  border-top: 2px solid #229556;
  padding: 0;
}
.dropdown-menu a {
  color: rgb(56, 56, 56);
  line-height: 40px;
}
.dropdown-menu a:hover {
  background: none;
  color: #188038;
}
.stat-wrapper .custom-card {
  text-align: center;
}
.stat-wrapper i {
  font-size: 54px;
  margin-bottom: 20px;
  color: #595959;
}
.stat-wrapper h4 {
  color: #595959;
  font-size: 36px;
  font-weight: 800;
}
.stat-wrapper .col-md-4:nth-child(2) i {
  color: #229556;
}
.stat-wrapper .col-md-4:last-child i {
  color: #cd2817;
}
.ml-1 {
  margin-left: 10px;
}
.ml-2 {
  margin-left: 20px;
}
.mt-120 {
  margin-top: 120px;
}
.profile-wrapper {
  display: flex;
  justify-content: space-between;
}
.profile-wrapper .profile-img .user-image {
  width: 100px;
  border-radius: 50%;
  height: 100px;
}

.profile-wrapper .rating-wrapper i {
  color: #a7a7a7;
}
.profile-wrapper .rating-wrapper i.active {
  color: #eda626;
}
.profile-wrapper .border-box {
  padding: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  text-align: left;
}
.profile-wrapper h5 {
  color: #979797;
  font-weight: 700;
  font-size: 16px;
}
.profile-wrapper h4 {
  font-size: 18px;
  font-weight: 700;
  color: #6e6e6e;
}

.w-320 {
  min-width: 320px;
}
.btn-outline.btn-sm,
.btn-main.btn-sm {
  font-size: 14px;
  text-transform: uppercase;
}
.btn-block {
  display: block;
  width: 100%;
}
.close-modal {
  font-size: 28px;
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
  color: rgb(228, 84, 84);
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.pay-card {
  margin-bottom: 10px;
}

.custom-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.custom-checkbox label {
  position: relative;
  cursor: pointer;
}

.custom-checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #229556;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.custom-checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.badge-dim,
.btn-secondary {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd;
  border-radius: 10px;
}

.btn-primary,
.badge-primary {
  color: #fff;
  background-color: #4582ec;
  border-color: #4582ec;
  border-radius: 10px;
}

.btn-success,
.badge-success {
  color: #fff;
  background-color: #02b875;
  border-color: #02b875;
  border-radius: 10px;
  padding: 4px 14px;
}
.btn-info,
.badge-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  border-radius: 10px;
  padding: 4px 14px;
}

.btn-warning,
.badge-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  border-radius: 10px;
  padding: 4px 14px;
}

.btn-danger,
.badge-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
  border-radius: 10px;
  padding: 4px 14px;
}

.react-tabs__tab {
  border: 1px solid #188038 !important;
  background: #fff;
  margin-right: 10px;
  border-radius: 5px;
}
.react-tabs__tab--selected {
  border-radius: 5px !important;
  background: #188038 !important;
  color: #fff !important;
}
.booking-list-design {
  margin-top: 15px;
}
.route-info p {
  font-size: 16px;
  text-align: left;
}
.booking-list-design .year-wrap {
  font-size: 34px;
  display: block;
  text-align: center;
  font-weight: 900;
}
.booking-list-design .date-wrap {
  font-size: 34px;
  display: block;
  text-align: center;
  font-weight: 900;
}
.booking-list-design .month-year-wrap {
  font-size: 18px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  font-weight: 800;
  color: #adadad;
}
.booking-list-design .date-time-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.booking-list-design .time-wrap {
  font-size: 16px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  font-weight: 800;
  color: #adadad;
}
.booking-list-design .fa-map-marker-alt,
.booking-list-design .fa-map-marker {
  color: #36b168;
}
.booking-list-design .fa-map-pin {
  color: #fb6e6e;
}
.booking-list-design .route-info h5 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 8px;
  width: 310px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #747474;
  overflow: hidden;
}
.booking-list-design .fare-wrapper .badge {
  display: block;
  padding: 8px 0;
  margin-bottom: 5px;
}
.booking-list-design .fare-wrapper p {
  font-size: 32px;
  display: block;
  color: #2a2a2a;
  text-align: center;
  margin-top: 20px;
  font-weight: 800;
}

.booking-list-design .fare-wrapper p sup {
  position: relative;
  left: -8px;
  color: #444;
  font-weight: 700;
}
.badge {
  background: #ddd;
  border-radius: 10px;
  color: #000;
}
.custom-card.booking-list-design {
  padding: 10px;
  margin-bottom: 10px;
  text-align: left !important;
}

.modal-xl .modal-content {
  overflow: scroll;
  max-height: 80vh;
}
.vehicle-img {
  width: 70px;
  height: 70px;
  object-fit: contain;
  border-radius: 50%;
  transition: all ease 0.5s;
}
.profile-img {
  width: 140px;
  text-align: center;
  object-fit: cover;
  border-radius: 50%;
  transition: all ease 0.5s;
}
.modal-xl .modal-content strong {
  font-weight: 800 !important;
}

.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 20px;
}
.mr-3 {
  margin-right: 30px;
}
.mr-4 {
  margin-right: 40px;
}
.mr-5 {
  margin-right: 50px;
}

.sidebar-filter {
  text-align: left !important;
}
.sidebar-filter h3 {
  font-size: 22px;
}
.doc-stat-wrap .custom-card {
  text-align: left !important;
}

.doc-stat-wrap h3 {
  font-weight: 700;
}
.doc-stat-wrap p {
  color: #188038;
  font-weight: 700;
}
.mob-header {
  display: none;
}

@media (max-width: 1199px) {
  .w-320 {
    min-width: auto;
  }
  .form-wrapper {
    width: 50%;
  }
}
@media (max-width: 991px) {
  header .left-side a {
    font-size: 15px;
  }

  header .right-side #dropdown-basic {
    font-size: 14px;
  }
  header .left-side {
    gap: 30px;
  }
 
  .card-page .row .col-md-5, .profile-page .row .col-md-6 {
    width: 70% !important;
  } 
}
@media (max-width: 768px) {
  .web-header {
    display: none;
  }
  .card-page .row .col-md-5, .profile-page .row .col-md-6 {
    width: 100% !important;
  }
  .mob-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .form-wrapper {
    width: 70%;
  }
  .responsive-menu-btn i {
    font-size: 24px;
    color: #647c73;
  }
  .nav.mob-nav.hide {
    height: 0;
    transition: all ease 0.3s;
    opacity: 0;
  }
  .nav.mob-nav li a {
    color: #000;
  }
  .nav.mob-nav li {
    line-height: 44px;
  }
  .nav.mob-nav.show {
    opacity: 1;
    transition: all ease 0.3s;
    display: block;
    position: fixed;
    width: 100%;
    background: #fff;
    top: 60px;
    padding: 20px;
    text-align: center;
    z-index: 9999999;
  }
  .react-tabs__tab {
    padding: 10px 10px !important;
    margin-bottom: 10px;
  }
  .profile-details h6 {
    word-break: break-all;
  }
}
@media (max-width: 640px) {
  .form-wrapper {
    width: 90% !important;
  }
  .termsservice-desc {
    padding: 0 40px !important;
  }
}
@media (max-width: 480px) {
  .profile-wrapper {
    display: block;
  }
  .profile-img {
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .profile-page .btn-wrap{
    display: block !important;
  }
  .profile-page .btn-wrap .btn-outline.ml-2{
    margin-left: 0;
    margin-top: 20px;
  }
    .faqlist-bg{
      padding: 10px;
    }
}
